import React, { useContext } from 'react';
import './style.scss';
import { AppContext } from "../../context/AppContext";
import Basket from "../../../images/BasketAcout.svg"

const CartIcon = () => {

  const { cart, toogleShowFlyCart, pageName } = useContext(AppContext);
  // const [isDropdownOpen, setIsDropdownOpen, ] = useState(false);

  const productsCount = (null !== cart && Object.keys(cart).length) ? cart.totalProductsCount : '';

  return (
    <>
      <button className="woo-menu-cart-icon" onClick={() => {
        if (pageName !== 'checkout' && pageName !== 'cart') {
          toogleShowFlyCart(true)
        }

      }}>
        <div className="woo-next-cart-wrap">
          <span className="woo-next-cart-icon-container">
            <span role="img" aria-label="cart-icon">
              <Basket />
            </span>
            {productsCount ? <span className="woo-next-cart-count">{productsCount}</span> : ''}
          </span>
        </div>
      </button>
    </>

  )
};

export default CartIcon;
