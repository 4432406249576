/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import HeaderPL from "./header/HeaderPL"
import FooterPL from "./footer/FooterPL"
import "./layout.css"
import "../fonts/fonts.css"
import "../sass/global.scss"

import { Helmet } from "react-helmet"
import { getCookie } from "../utils/functions"
import HeaderDE from "./header/HeaderDE"
import FooterDE from "./footer/FooterDE"
import HeaderEN from "./header/HeaderEN"
import FooterEN from "./footer/FooterEN"
import FooterFR from "./footer/FooterFR"
import HeaderFR from "./header/HeaderFR"

const Layout = ({ children, lang, page, translated }) => {
  return (
    <>
      {getCookie("gatsby-gdpr") ? (
        <Helmet>
          <script>
            {`
              const dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', '${process.env.googleAnalytics}');
          `}
          </script>
          <script
            type="text/javascript"
            ata-rh="true"
            data-react-helmet="true"
            src={`https://t.goadservices.com/tags/${process.env.goPl}`}
          ></script>
          <script type="text/javascript" ata-rh="true" data-react-helmet="true">
            {`var __cp = {"id":"rYoLJd4_L5o1cmeJG5yxb4wKLClfsO_aBdG66MOSfW4","version":"1.1"};(function (window, document) {var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = false;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function (method) { if (method == '__getQueue') { return this.methods; } else if (method) { if (typeof window.callpage.execute === 'function') { return window.callpage.execute.apply(this, arguments); } else { (this.methods = this.methods || []).push({ arguments: arguments }); } } };window.callpage.__cp = __cp;window.callpage('api.button.autoshow');}})(window, document);`}
          </script>
        </Helmet>
      ) : (
        ""
      )}

      {lang === "pl" && (
        <>
          <HeaderPL lang={lang} translated={translated} />
          <main>{children}</main>
          <FooterPL page={page} />
        </>
      )}

      {lang === "de" && (
        <>
          <HeaderDE lang={lang} translated={translated} />
          <main>{children}</main>
          <FooterDE page={page} />
        </>
      )}

      {lang === "en" && (
        <>
          <HeaderEN lang={lang} translated={translated} />
          <main>{children}</main>
          <FooterEN page={page} />
        </>
      )}

      {lang === "fr" && (
        <>
          <HeaderFR lang={lang} translated={translated} />
          <main>{children}</main>
          <FooterFR page={page} />
        </>
      )}
    </>
  )
}

Layout.propTypes = {
  lang: PropTypes.string,
  page: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
