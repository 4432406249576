import React, { useState, useContext } from "react"
import { useMutation, useLazyQuery } from "@apollo/client"
import { AppContext } from "../context/AppContext"
import { getFormattedCart } from "../../utils/functions"
import { v4 } from "uuid"
import GET_CART from "../../queries/get-cart"
import ADD_TO_CART from "../../mutations/add-to-cart"
import "./style.scss"
import Spin from "../spin/Spin"
import FlyCartAddIcon from "../../images/fly_cart_add.svg"

const ButtonAddToCart = ({ title, quantity, productID, icon = false }) => {
  /* eslint-disable */
  const {
    setCart,
    toogleShowFlyCart,
    lang,
    shippingID,
    setAddToCartError,
    setAddToCartErrorIdProduct,
  } = useContext(AppContext)
  const [addingToCart, setAddingToCart] = useState(false)
  const [requestError, setRequestError] = useState(null)

  const productQtyInput = {
    clientMutationId: v4(), // Generate a unique id.
    productId: +productID,
    quantity: +quantity,
    wpmlLanguage: lang,
    // items: {
    // }
  }
  // Get Cart Data.
  const [getCart, { error, data }] = useLazyQuery(GET_CART, {
    variables: {
      country: lang.toUpperCase(),
      shippingID: shippingID,
      wpmlLanguage: lang,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: () => {
      // console.warn( 'completed GET_CART' );
      if (data) {
        // Update cart in the localStorage.
        const updatedCart = getFormattedCart(data)
        localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
        setAddingToCart(false)
        toogleShowFlyCart(true)
        // Update cart data in React Context.
        setCart(updatedCart)
      }
    },
  })

  // Add to Cart Mutation.
  const [addToCart, {}] = useMutation(ADD_TO_CART, {
    variables: {
      input: productQtyInput,
    },
    onCompleted: () => {
      // If error.
      // if (addToCartError) {
      //   if (addToCartError.graphQLErrors) {
      //     setRequestError(addToCartError.graphQLErrors[0].message);
      //   }
      // }

      // On Success:
      // 1. Make the GET_CART query to update the cart with new values in React context.
      getCart()
      setAddToCartError("")
      // getCartRefetch();
      // 2. Show View Cart Button
      // setShowViewCart(true);
    },
    onError: errors => {
      if (errors) {
        // console.log(errors?.graphQLErrors[0]?.message);
        if (errors?.graphQLErrors[0]?.message === "Internal server error") {
          setAddToCartError("")
          getCart()
        } else {
          setAddingToCart(false)
          // setRequestError(errors?.graphQLErrors?.[0]?.message ?? "")
          setAddToCartError(errors?.graphQLErrors[0]?.message)
          setAddToCartErrorIdProduct(+productID)
        }
      }
    },
  })

  const handleAddToCartClick = () => {
    setRequestError(null)
    setAddingToCart(true)
    addToCart()
  }

  return (
    <div className={`addToCart ${icon ? "" : "addToCart__text"}`}>
      {icon ? (
        <a
          className="addToCart__icon"
          onClick={e => {
            e.preventDefault()
            handleAddToCartClick()
          }}
        >
          <FlyCartAddIcon />
          <span>{title}</span>
        </a>
      ) : (
        <a
          className="btn"
          onClick={e => {
            e.preventDefault()
            handleAddToCartClick()
          }}
        >
          {title}
        </a>
      )}
      {addingToCart && <Spin />}
    </div>
  )
}
export default ButtonAddToCart
