/**
 * External dependencies.
 */
 import PropTypes from "prop-types"
 import React from "react"
 import { StaticQuery, graphql } from "gatsby"
 import Header from "./header"
 
 const HeaderFR = ({ translated }) => {
   return (
     <StaticQuery
       query={graphql`
         query HeaderQueryFR {
           wpgraphql {
             themesOptionsPageFr {
               template {
                 header {
                   homepage
                   searchpage
                   myaccountpage
                   topbanner
                   searchPlaceholder
                   searchButton
                   logo {
                     sourceUrl
                     databaseId
                     modified
                     imageFile {
                       childImageSharp {
                         fluid(maxWidth: 150) {
                           base64
                           aspectRatio
                           src
                           srcSet
                           sizes
                           srcWebp
                           srcSetWebp
                         }
                       }
                     }
                   }
                   nav {
                     item {
                       url
                       title
                       target
                     }
                     subnav {
                       item {
                         target
                         title
                         url
                       }
                     }
                   }
                   helpline {
                     target
                     title
                     url
                   }
                 }
               }
             }
           }
         }
       `}
       render={data => (
         <Header
           translated={translated}
           dataTemplate={data.wpgraphql.themesOptionsPageFr}
         />
       )}
     />
   )
 }
 
 HeaderFR.propTypes = {
   lang: PropTypes.string,
 }
 
 HeaderFR.defaultProps = {
   lang: "fr",
   data: {
     wpgraphql: {},
   },
 }
 
 export default HeaderFR
 