import React from 'react';
import Link from 'gatsby-link';
import Img from "gatsby-image"
import { v4 as uuid } from 'uuid';
import Button from "../button/Button";
import { removeHost } from "../../utils/functions";
import "./style.scss"
import Facebook from "../../images/facebook.svg"
import Instagram from "../../images/instagram.svg"
import Tiktok from "../../images/tiktok.svg"
import Pinterest from "../../images/pinterest.svg"
import CookieConsent from "react-cookie-consent"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const Footer = ({ dataTemplate, page }) => {
  const footerbar = dataTemplate.template.footer.footerbar;
  const textClients = dataTemplate.template.footer.textClients;
  const textFullspectrum = dataTemplate.template.footer.textFullspectrum;
  const textProducts = dataTemplate.template.footer.textProducts;
  const logo = dataTemplate.template.header.logo.imageFile.childImageSharp.fluid
  const homeUrl = dataTemplate.template.header.homepage;
  const navProducts = dataTemplate.template.footer.navProducts;
  const navFullspectrum = dataTemplate.template.footer.navFullspectrum;
  const navClients = dataTemplate.template.footer.navClients;
  const counselorImg = dataTemplate.template.footer.counselorImg.imageFile.childImageSharp.fluid
  const textCounselor = dataTemplate.template.footer.textCounselor;
  const counselorDescription = dataTemplate.template.footer.counselorDescription;
  const copy = dataTemplate.template.footer.copy
  const socialmedia = dataTemplate.template.socialmedia
  const cookies = dataTemplate.template.cookies
  // const
  const location = useLocation()

  return (
    <>
      <footer className={`${footerbar && page === "home" ? 'footer__sticky--active' : ''} footer`}>
        <div className="footer__content">
          <div className="container">
            <div className="footer__content__logo">
              <Link to={homeUrl}>
                <Img fluid={logo} />
              </Link>
            </div>
          </div>
          <div className="container">
            <div className="footer__content__wrapper">
              <div className="footer__content__column">
                <p className="footer__content__heading">{textProducts}</p>
                {navProducts && (
                  <nav>
                    <ul>
                      {navProducts.map(item => (
                        <li key={uuid()}>
                          <Link to={removeHost(item.link.url)} key={uuid()} target={item.link.target ? item.link.target : '_self'} >
                            {item.link.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                )}
              </div>
              <div className="footer__content__column">
                <p className="footer__content__heading">{textClients}</p>
                {navClients && (
                  <nav>
                    <ul>
                      {navClients.map(item => (
                        <li key={uuid()}>
                          <Link to={removeHost(item.link.url)} key={uuid()} target={item.link.target ? item.link.target : '_self'} >
                            {item.link.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                )}
              </div>
              <div className="footer__content__column">
                <p className="footer__content__heading">{textFullspectrum}</p>
                {navFullspectrum && (
                  <nav>
                    <ul>
                      {navFullspectrum.map(item => (
                        <li key={uuid()}>
                          <Link to={removeHost(item.link.url)} key={uuid()} target={item.link.target ? item.link.target : '_self'} >
                            {item.link.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                )}
              </div>
              <div className="footer__content__column footer__content__column--social">
                <p className="footer__content__heading">{textCounselor}</p>
                <div className="footer__content__img">
                  <Img fluid={counselorImg} />
                </div>
                <p className="footer__content__para">{counselorDescription}</p>
                {socialmedia && (
                  <div className="footer__socialmedia">
                    {socialmedia.map(item => (
                      <div key={uuid()} className="footer__socialmedia__item">
                        <a to={item.link.url} target="_blank">
                          {item.type === 'facebook' ? (<Facebook />) : ''}
                          {item.type === 'instagram' ? (<Instagram />) : ''}
                          {item.type === 'tiktok' ? (<Tiktok />) : ''}
                          {item.type === 'pinterest' ? (<Pinterest />) : ''}
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="footer__copy">
          <div className="container">
            <div className="footer__copy__wrapper">
              <p>{copy}</p>
              <p>Made by <a href="https://mobilitysoft.pl/" target="_blank">Mobility Soft</a></p>
            </div>
          </div>
        </div>
      </footer>
      {footerbar && page === "home" ? (
        <div className="footer__sticky">
          <div className="container">
            <div className="footer__sticky__wrapper">
              <p>{footerbar.text}</p>
              <Button
                target={footerbar.button.target}
                title={footerbar.button.title}
                url={footerbar.button.url}
              />
            </div>
          </div>
        </div>
        
      ) : ''}
      <CookieConsent
        disableStyles={true}
        containerClasses="customCookieConsent CookieConsent"
        enableDeclineButton
        location="bottom"
        buttonText={cookies.buttonRejected}
        declineButtonText={cookies.buttonAccept}
        cookieName="gatsby-gdpr"
        buttonWrapperClasses="CookiesConsent__buttons__wrapper"
        expires={365}
        buttonClasses="btn btn--secondary"
        declineButtonClasses="btn"
        onAccept={() => {
          initializeAndTrack(location);
        }}
      >
        <h2 className="heading--small">{cookies.heading}</h2>
        <p>
        {cookies.description}
        </p>
      </CookieConsent>
    </>
  )
}
export default Footer